import { markdown } from "./theme/markdown";

/**
 * Breakpoints
 *
 * 0em   40em   52em    64em    75em
 * 0px  640px  832px  1024px  1200px
 */
const breakpoints = [40, 52, 64, 75].map((n) => n + "em");

/**
 * Fontsizes
 *
 * 0   1   2   3   4   5   6
 * 14  16  20  30  40  60  80
 */
const fontSizes = [14, 16, 20, 30, 40, 60, 80] as const;

export const textBase = {
  f1: { fontSize: [4, 4, 5, 6], fontFamily: "body", lineHeight: 1.25 },
  f2: { fontSize: [3, 3, 4, 5], fontFamily: "body", lineHeight: 1.25 },
  f3: { fontSize: [3, 3, 3, 4], fontFamily: "body", lineHeight: 1.25 },
  f4: { fontSize: [2, 2, 2, 3], fontFamily: "body", lineHeight: 1.5 },
  f5: { fontSize: [1, 2, 2, 2, 2], fontFamily: "body", lineHeight: 1.5 },
  f6: { fontSize: [0, 0, 1, 1, 1], fontFamily: "body", lineHeight: 1.5 },
  f7: { fontSize: [0], fontFamily: "body", lineHeight: 1.5 },
} as const;

export const textBold = {
  f1_bold: { ...textBase.f1, fontWeight: "bold" },
  f2_bold: { ...textBase.f2, fontWeight: "bold" },
  f3_bold: { ...textBase.f3, fontWeight: "bold" },
  f4_bold: { ...textBase.f4, fontWeight: "bold" },
  f5_bold: { ...textBase.f5, fontWeight: "bold" },
  f6_bold: { ...textBase.f6, fontWeight: "bold" },
  f7_bold: { ...textBase.f7, fontWeight: "bold" },
} as const;

export const textMono = {
  f1_mono: { ...textBase.f1, fontFamily: "monospace", fontWeight: "bold" },
  f2_mono: { ...textBase.f2, fontFamily: "monospace", fontWeight: "bold" },
  f3_mono: { ...textBase.f3, fontFamily: "monospace", fontWeight: "bold" },
  f4_mono: { ...textBase.f4, fontFamily: "monospace", fontWeight: "bold" },
  f5_mono: { ...textBase.f5, fontFamily: "monospace", fontWeight: "bold" },
  f6_mono: { ...textBase.f6, fontFamily: "monospace", fontWeight: "bold" },
  f7_mono: { ...textBase.f7, fontFamily: "monospace", fontWeight: "bold" },
} as const;

export const textVariants = {
  ...textBase,
  ...textBold,
  ...textMono,
} as const;

export const mkSecondaryPalette = (p: number) =>
  [
    "rgba(229,112,10,$opacity)", //orange
    "rgba(253,195,0,$opacity)", //yellow
    "rgba(196,182,8,$opacity)", //yellowGreen
    "rgba(123,159,45,$opacity)", //lightGreen
    "rgba(0,112,59,$opacity)", //darkGreen
    "rgba(62,138,183,$opacity)", //blue
    "rgba(120,183,229,$opacity)", //lightBlue
  ].map((d) => d.replace("$opacity", p.toString()));

export const theme = {
  breakpoints,
  colors: {
    text: "#000",
    background: "white",
    contrastBackground: "#F2F3F4",
    primary: "#000",
    accent: "#C81E32",
    red: "#C81E32",
    grayLight: "#F8F9F9",
    grayMedium: "#F2F3F4",
    grayDark: "#6E6F6F",
    grayAlpha010: "rgba(0, 0, 0, 0.1)",
    grayAlpha050: "rgba(0, 0, 0, 0.5)",
    innovation: "#E5700A",
    innovationLight: "#F0B683",
    cypnetBlue: "#3E8AB7",
  },
  fontSizes,
  fontWeights: {
    body: 300,
    heading: 300,
    bold: 400,
  },
  shadows: {
    elevation0: "none",
    elevation1: "0 0.6px 1.3px -5px rgba(0, 0, 0, 0.035), 0 5px 10px -5px rgba(0, 0, 0, 0.07)",
    elevation2: "0 1.9px 2.5px -10px rgba(0, 0, 0, 0.035), 0 15px 20px -10px rgba(0, 0, 0, 0.07)",
    elevation3: "0 3.1px 4.4px -20px rgba(0, 0, 0, 0.1), 0 25px 35px -20px rgba(0, 0, 0, 0.18)",
    elevation4: "0 3.8px 5px -30px rgba(0, 0, 0, 0.25), 0 30px 40px -30px rgba(0, 0, 0, 0.5)",
    default: "0 3.1px 4.4px -20px rgba(0, 0, 0, 0.1), 0 25px 35px -20px rgba(0, 0, 0, 0.18)",
  },
  fonts: {
    body: '"Roboto Condensed", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "inherit",
    monospace: "'Roboto Mono', Menlo, monospace",
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    action: {
      fontFamily: "monospace",
    },
    dt: {
      fontWeight: "bold",
    },
    titleMedium: {
      fontWeight: "bold",
      my: "0.125em",
      fontSize: 2,
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    ...textVariants,
  },
  links: {
    noHover: {
      textDecoration: "none",
    },
    default: {
      color: "accent",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.7,
      },
    },
    mono: {
      variant: "links.default",
      fontFamily: "monospace",
      textDecoration: "none",
      fontWeight: "bold",
    },
    icon: {
      bg: "accent",
      variant: "links.mono",
      display: "flex",
      alignItems: "center",
      color: "text",

      "& svg": {
        ml: 1,
        color: "accent",
        transition: "all .2s",
      },

      "&:hover svg": {
        transform: "translateX(4px)",
      },
    },
    arrow_left: {
      variant: "links.default",
      cursor: "pointer",
      alignItems: "center",
      backgroundImage: 'url("/icons/arrow-left.svg")',
      backgroundPosition: "4px 0px",
      backgroundRepeat: "no-repeat",
      transition: ".2s background-position",
      textDecoration: "none",
      pl: 4,
      "&:hover": {
        textDecoration: "none",
        backgroundPosition: "0px 0px",
      },
    },
    arrow_right: {
      variant: "links.arrow_left",
      backgroundImage: 'url("/icons/arrow-right-orange.svg")',
      backgroundPosition: "right 4px top 0px",
      pl: 0,
      pr: 4,
      "&:hover": {
        backgroundPosition: "right 0px top 0px",
      },
    },
    arrow_left_mono: {
      variant: "links.arrow_left",
      fontWeight: "bold",
      fontFamily: "monospace",
    },
    arrow_left_mono_text: {
      variant: "links.arrow_left",
      backgroundImage: 'url("/icons/arrow-left-black.svg")',
      fontWeight: "bold",
      fontFamily: "monospace",
    },
    arrow_right_mono: {
      variant: "links.arrow_right",
      fontWeight: "bold",
      fontFamily: "monospace",
    },
    external: {
      variant: "links.arrow_right",
      backgroundImage: 'url("/icons/link-external.svg")',
    },
    external_mono: {
      variant: "links.arrow_right_mono",
      backgroundImage: 'url("/icons/link-external.svg")',
    },
    button: {
      display: "inline-block",
      bg: "accent",
      color: "white",
      fontFamily: "monospace",
      fontWeight: "bold",
      py: 2,
      pl: 3,
      pr: "38px",
      textDecoration: "none",
      textAlign: "left",
      backgroundImage: 'url("/icons/arrow-right.svg")',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 0.5em center",
      transition: ".2s background-position",
      "&:disabled": {
        cursor: "initial",
        opacity: 0.2,
      },
      "&:hover:not(:disabled)": {
        backgroundPosition: "right 0.25em center",
        opacity: 0.8,
      },
      "&:focus": {
        opacity: 0.5,
      },
    },
  },
  buttons: {
    naked: {
      bg: "transparent",
      border: "none",
      cursor: "pointer",
      p: 0,
    },
    default: {
      ...textVariants.f6_mono,
      px: 3,
      py: 2,
      cursor: "pointer",
      textDecoration: "none",
      borderRadius: 0,
      borderStyle: "solid",
      borderWidth: "2px",
      borderColor: "primary",
      "&:disabled": {
        cursor: "initial",
        opacity: 0.2,
      },
      "&:hover:not(:disabled)": {
        opacity: 0.8,
      },
      "&:focus": {
        opacity: 0.5,
      },
    },
    primary: {
      variant: "buttons.default",
      color: "white",
    },
    accent: {
      variant: "buttons.default",
      bg: "accent",
      color: "white",
      borderColor: "currentColor",
    },
    text: {
      variant: "buttons.default",
      color: "accent",
      bg: "transparent",
      borderColor: "transparent",
    },
    ghost: {
      boxSizing: "border-box",
      variant: "buttons.default",
      color: "text",
      bg: "transparent",
      borderColor: "currentColor",
    },
    tag: {
      variant: "buttons.default",
      bg: "grayMedium",
      borderColor: "grayMedium",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 0.75em center",
      color: "text",
      fontWeight: "body",
      borderWidth: "1px",
      ...textVariants.f6,
    },
    icon: {
      bg: "black",
      color: "white",
      borderRadius: 0,
    },
  },
  box: {
    fitImage: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
    },
  },
  styles: {
    /*
     * Default style for `<TUI.Link>`
     */
    a: {
      variant: "links.default",

      "&.button": {
        display: "inline-block",
        bg: "accent",
        color: "white",
        fontFamily: "monospace",
        fontWeight: "bold",
        py: 2,
        pl: 3,
        pr: "38px",
        textDecoration: "none",
        textAlign: "left",
        backgroundImage: 'url("/icons/arrow-right.svg")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right 0.5em center",
        transition: ".2s background-position",
        "&:disabled": {
          cursor: "initial",
          opacity: 0.2,
        },
        "&:hover:not(:disabled)": {
          textDecoration: "none",
          backgroundPosition: "right 0.25em center",
          opacity: 0.8,
        },
        "&:focus": {
          opacity: 0.5,
        },
      },
    },
  },
  markdown: {
    ...markdown,
  },
  forms: {
    input: {
      variant: "text.f7_mono",
      bg: "grayMedium",
      pr: [3, 4],
      pl: 3,
      py: "1em",
      borderRadius: 0,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "grayMedium",
      "&:focus": {
        borderColor: "text",
      },
      "&:disabled": {
        color: "grayDark",
        cursor: "not-allowed",
      },
    },
    textarea: {
      variant: "forms.input",
    },
    select: {
      variant: "forms.input",
    },
  },
};

export const globalStyles = `
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto Condensed, -apple-system, BlinkMacSystemFont, Segoe UI,
      Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
      Segoe UI Symbol;
    font-weight: 300;
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
    svg,
    img {
      display: block;
    }
    overscroll-behavior: none;
    // Use momentum-based scrolling on iOS devices
    -webkit-overflow-scrolling: touch;
    // Auto-hide scrollbars in Edge
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .tippy,
  [data-tippy-content] {
    text-decoration: underline dotted;
    cursor: help;
  }
  .tippy-box[data-theme~="light"] {
    padding: 1.5em;
    a {
      color: currentColor;
      text-decoration: underline;
      transition: 0.4s opacity;
    }
    a:hover {
      opacity: 0.8;
    }
  }

  // Hide google reCaptcha badge
  .grecaptcha-badge {
    visibility: hidden;
  }

  /*
   * This is to block context menu on images (so that non tech-savvy users can't easily
   * download images).
   */
  img {
    pointer-events: none;
  }

`;

interface PageThemeOptions {
  colors: {
    primary: string;
  };
}

export const pageTheme = (options: PageThemeOptions) => ({
  colors: {
    primary: options.colors.primary,
    accent: options.colors.primary,
  },
  styles: {
    a: {
      variant: "links.default",

      ...(options.colors.primary === "#000000" && {
        textDecoration: "underline",
      }),

      /*
       * This is to allow use of `<a className="button">` within markdown blocks.
       */
      "&.button": {
        display: "inline-block",
        bg: "accent",
        color: "white",
        fontFamily: "monospace",
        fontWeight: "bold",
        py: 2,
        pl: 3,
        pr: "38px",
        textDecoration: "none",
        textAlign: "left",
        backgroundImage: 'url("/icons/arrow-right.svg")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right 0.5em center",
        transition: ".2s background-position",
        "&:disabled": {
          cursor: "initial",
          opacity: 0.2,
        },
        "&:hover:not(:disabled)": {
          backgroundPosition: "right 0.25em center",
          opacity: 0.8,
        },
        "&:focus": {
          opacity: 0.5,
        },
      },
    },
  },
});
