import { GoogleTagManager } from "@/components/GoogleTagManager";
import { globalStyles, theme } from "@/theme";
import { css, Global } from "@emotion/core";
import { withProfiler } from "@sentry/react";
import "fontsource-roboto-condensed/latin.css";
import "fontsource-roboto-mono/latin.css";
import { AnimatePresence } from "framer-motion";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ThemeProvider } from "theme-ui";
import { delegate } from "tippy.js";
import "tippy.js/dist/backdrop.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

/*
 * Load CookieConsent dynamically, so it doesn't end up in the common bundle.
 */
const CookieConsent = dynamic(
  (): Promise<React.ComponentType<{}>> => import("../components/CookieConsent").then((m) => m.CookieConsent)
);

function App({ Component, pageProps, router }: AppProps) {
  const [hash, setHash] = useState<string>();

  useEffect(() => {
    const handleHashChange = () => setHash(window.location.hash);
    window.addEventListener("hashchange", handleHashChange);

    /**
     * Initialize tippy.js
     */
    delegate("#__next", {
      theme: "light",
      target: "[data-tippy-content]",
      allowHTML: true,
      interactive: true,
      appendTo: document.body,
    });

    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  /*
   * Dealing with preview mode, complicated…
   */
  useEffect(() => {
    if (process.env.PREVIEW_DOMAIN && process.env.PREVIEW_SECRET_TOKEN) {
      const PREVIEW_SECRET_TOKEN = process.env.PREVIEW_SECRET_TOKEN;

      if (window.location.host === process.env.PREVIEW_DOMAIN) {
        console.log("on preview domain");
        if (PREVIEW_SECRET_TOKEN in router.query) {
          fetch("/api/preview").then(() => {
            /*
             * Delete the PREVIEW_SECRET_TOKEN from the URL and reload the page.
             *
             * Use the actual, real location from the window rather than router.pathname,
             * as the pathname in the router is "404" when the page is draft and not
             * visible to a non-preview user.
             */
            const url = new URL(window.location.toString());
            url.searchParams.delete(PREVIEW_SECRET_TOKEN);
            window.location.href = url.toString();
          });
        }
      } else if (PREVIEW_SECRET_TOKEN in router.query) {
        console.log("not on preview domain, redirecting");
        window.location.href = `${window.location.protocol}//${process.env.PREVIEW_DOMAIN}${window.location.pathname}${window.location.search}`;
      }
    }
  }, [router.query]);

  useEffect(() => {
    if (hash) {
      const $el = document.getElementById(hash.replace(/#/, ""));
      if ($el) {
        setTimeout(() => {
          $el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }, 100);
      }
    }
  }, [hash]);

  return (
    <>
      <Global
        styles={css`
          ${globalStyles}
        `}
      />

      <ThemeProvider theme={theme}>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.CAPTCHA_KEY}>
          <AnimatePresence mode="wait">
            <Component {...pageProps} key={router.route} />
          </AnimatePresence>
        </GoogleReCaptchaProvider>

        {(() => {
          const { asPath } = router;

          /*
           * Disable the cookie consent and GA tracking on these pages…
           */
          if (asPath.startsWith("/docs") || asPath.startsWith("/lobby")) {
            return null;
          }

          return (
            <>
              <CookieConsent />
              <GoogleTagManager />
            </>
          );
        })()}
      </ThemeProvider>
    </>
  );
}

export default withProfiler(App);
