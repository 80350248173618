import { useRouter } from "next/router";
import Script from "next/script";
import React from "react";
import data from "./data";

function pageview(url: string) {
  window.dataLayer?.push({ event: "pageview", page: url });
}

function GoogleTagManager() {
  const { containerId } = data;

  const router = useRouter();
  React.useEffect(() => {
    router.events.on("routeChangeComplete", pageview);
    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [router.events]);

  if (!containerId) {
    return null;
  }

  return (
    <Script
      id="GoogleTagManager"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${containerId}');
        `,
      }}
    />
  );
}

export default GoogleTagManager;
