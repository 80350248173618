export const definitionLists = {
  dl: {
    display: "grid",
    gridTemplateColumns: ["1fr", "minmax(max-content, 200px) auto"],
    mb: 5,
    borderTop: "1px solid #ccc",
  },
  dt: {
    py: 2,
    pr: 3,
    variant: "text.f6_bold",
    borderBottom: ["none", "1px solid #ccc"],
  },
  "dt + dt": {
    pt: 0,
  },
  dd: {
    py: 2,
    variant: "text.f6",
    marginInlineStart: 0,
    borderBottom: "1px solid #ccc",
  },
  ".description-item": {
    gridColumn: "1 / -1",
    display: "grid",
    gridTemplateColumns: ["1fr", "minmax(max-content, 200px) auto"],
    dt: { border: "none" },
    "dt:last-of-type": {
      borderBottom: ["none", "1px solid #ccc"],
    },
  },
};
