import { definitionLists } from "./definitionList";

export const markdown = {
  main: {
    /*
     * Workaround for emotion v10 incompatibility with :first-child selectors.
     *
     * https://github.com/atlassian-labs/compiled-css-in-js/issues/31#issuecomment-612314400`
     */
    "& > style:first-of-type + *, & > *:first-of-type": {
      mt: 0,
      pt: 0,
    },
    "& > *:last-child": {
      mb: 0,
      pb: 0,
    },

    hr: {
      color: "grayAlpha010",
      my: 4,
    },

    /*
     * h{1,2} are not used supported "markdown.main"
     */
    h3: { variant: "text.f4", fontWeight: "bold" },
    h4: { variant: "text.f5", fontWeight: "bold" },
    h5: { variant: "text.f6", fontWeight: "bold" },
    h6: { variant: "text.f7", fontWeight: "bold" },

    ...definitionLists,

    table: { width: "100%", borderCollapse: "collapse" },
    thead: { th: { borderBottom: "1px solid #000" } },

    button: {
      my: 1,
      bg: "primary",
      display: "block",
      maxWidth: "100%",
      variant: "buttons.primary",
    },
    "button[type=submit]": {
      mt: 3,
    },
    input: {
      variant: "forms.input",
      my: 1,
      px: 3,
      py: 2,
      fontSize: 2,
      maxWidth: "100%",
    },
    p: {
      whiteSpace: "pre-line",
      my: 4,
    },
    img: {
      maxWidth: "100%",
    },
    ul: {
      listStyleType: "none",
      li: {
        position: "relative",
      },
      "& li:before": {
        content: '"—"',
        mr: 3,
        position: "absolute",
        left: "-1.25em",
      },
    },
  },

  /*
   * Styles used for Markdown in the aside column.
   */
  aside: {
    variant: "text.f6_mono",
    fontWeight: "body",

    a: {
      "&:hover": {
        textDecoration: "underline",
      },
    },
    hr: {
      color: "grayAlpha010",
      my: 4,
    },

    /*
     * Only h3, h4 are supported in "markdown.aside".
     */
    h3: { variant: "text.f6_mono", fontWeight: "bold" },
    h4: { variant: "text.f7_mono", fontWeight: "bold" },

    button: {
      my: 1,
      bg: "primary",
      display: "block",
      maxWidth: "100%",
      variant: "buttons.primary",
    },
    input: {
      variant: "forms.input",
      my: 1,
      px: 3,
      py: 2,
      fontSize: 2,
      maxWidth: "100%",
    },
    p: {
      whiteSpace: "pre-line",
      my: 3,
    },
    img: {
      maxWidth: "100%",
    },
  },

  /*
   * Styles used for Markdown in the Contact Content Block.
   */
  contact: {},
};
